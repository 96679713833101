<template>
  <div id="mineAddress">
    <div v-if="formShow" class="getAddress">
      <van-form @submit="formSubmit">
        <van-cell-group>
          <!-- 姓名    收货人姓名    请填写用户名 -->
          <van-field v-model="formData.receiver_name" :error-message="formError.nameError" :disabled="isUpdate" @input="inputName" name="receiver_name" :label="language.l_121" :placeholder="language.l_122" autocomplete="off" clearable required :rules="[{ required: true, message: language.l_123 }]"/>
          <!-- 电话    收货人手机号    请填写手机号 -->
          <van-field v-model="formData.receiver_tel" :error-message="formError.phoneError"
            name="receiver_tel" :label="language.l_124" :placeholder="language.l_125" autocomplete="off" clearable required :rules="[{ required: true, message: language.l_126 }]"/>
            <!-- 身份证号    收货人身份证号    请填写身份证 -->
          <van-field v-model="formData.receiver_identity_card" :error-message="formError.idCardError" :disabled="hasIdCard" :label="language.l_127" required :placeholder="language.l_128" :right-icon="hasIdCard ? 'question-o' : ''" @click-right-icon="idCardIconClick" autocomplete="off" name="receiver_identity_card" clearable :rules="[{ required: true, message: language.l_129 }]" />
          <!-- 地区    选择省 / 市 / 区    请选择省市区 -->
          <van-field v-model="areaString" :error-message="formError.AdrError" @click="showArea" name="userAddress" :label="language.l_130" :placeholder="language.l_131" autocomplete="off" required readonly :rules="[{ required: true, message: language.l_132 }]"/>
          <!-- 详细地址    街道门牌、楼层房间号等信息    请填写详细地址 -->
          <van-field v-model="formData.address" :error-message="formError.AdrInfoError" @input="inputAdrInfo" name="address" :label="language.l_133" :placeholder="language.l_134" autocomplete="off" clearable required :rules="[{ required: true, message: language.l_135 }]"/>
          <!-- 设为默认地址 -->
          <van-field name="is_default" :label="language.l_136" input-align="right">
            <template #input>
              <van-switch active-color="#fd6650" v-model="formData.is_default" size="20" />
            </template>
          </van-field>
        </van-cell-group>
        <!-- 确认 -->
        <van-button class="getAdr" block native-type="submit">{{language.l_137}}</van-button>
        <!-- 删除地址 -->
        <van-button v-if="isUpdate" @click="delAddress" class="delAdr" block plain native-type="button">{{language.l_138}}</van-button>
        <!-- 取消 -->
        <van-button v-else @click="formCancel" class="delAdr" block plain native-type="button">{{language.l_139}}</van-button>
      </van-form>
    </div>
    <!-- </van-popup> -->

    <van-list v-else v-model="loading" :finished="finished">
      <div class="content">
        <div v-for="(item, index) in list" :key="index" @click="selectAddress(item)" class="content-wrap van-hairline--bottom">
          <div class="content-item">
            <div class="content-item-left">
              <div class="content-item-avaui">{{ (item.receiver_name).slice(0,1) }}</div>
              <div class="content-userInfo">
                <div class="content-receiver_name">
                  <p class="van-ellipsis">{{ item.receiver_name }}</p>
                  <p>{{ item.receiver_tel }}</p>
                </div>
                <div class="content-address">
                  <!-- 默认 -->
                  <p v-if="item.is_default == 1" class="default">{{language.l_140}}</p>
                  <p class="van-ellipsis">{{item.province + item.city + item.district + item.address}}</p>
                </div>
              </div>
            </div>
            <!-- 编辑 -->
            <p @click.stop="updateAddress(item)" class="content-change van-hairline--left">{{language.l_141}}</p>
          </div>
        </div>
      </div>
    </van-list>

    <!-- 选择省市区 -->
    <van-popup v-model="areaShow" position="bottom">
      <van-picker ref="picker" @confirm="clickArea" show-toolbar :columns="columns" @cancel="cancelArea">
        <template #columns-top>
          <div class="switching">
            <!-- 国内 -->
            <van-button @click="domestic" size="mini">{{textHome}}</van-button>
            <!-- 海外 -->
            <van-button @click="overseas" size="mini">{{texeForeign}}</van-button>
          </div>
        </template>
      </van-picker>
    </van-popup>

    <div v-if="!formShow" @click="showForm" class="addAdress">
      <!-- 新增收货地址 -->
      <p>{{language.l_144}}</p>
    </div>
  </div>
</template>

<script>
import {addRessList, addAddress, editAddress, delAear} from "@api";
export default {
  data() {
    return {
      params: {
        page: 1,
        page_size: 20,
      },
      fromPage: null, // 从哪个页面进入
      formShow: false,
      areaShow: false,
      formData: {
        id: "", //地址id
        receiver_name: "", //收件人
        receiver_tel: "", //收件人手机号码
        receiver_identity_card: "", //收件人身份证
        countries_code: "", //国家编码
        province_code: "", //省编码
        city_code: "", //城市编码
        district_code: "", //区县编码
        address: "", //详细地址
        is_default: "", //是否默认地址0：否，1：是；
      },
      areaString: "", //拼接地区
      regionalData: {}, //下拉选择地区数据
      active: 1, //默认国家
      formError: {
        nameError: "", // 收货人提醒
        phoneError: "", // 手机号提醒
        idCardError: "", // 身份证提醒
        AdrError: "", // 地址提醒
        AdrInfoError: "", // 详细地址提醒
      },
      areaCodeArr: null, // 地区编码
      district_code: null, // 区县编码
      isUpdate: false, // 是否编辑
      updateAdrId: null, // 编辑地址的id
      list: [],
      count: 0,
      loading: false,
      finished: true,
      columns: [],
      codeing: [],
      language: this.$store.getters.language,
      textHome: '',  //国内
      texeForeign: ''  //国外
    };
  },
  created() {
    // 如果vuex没有省市区数据则请求数据
    if (!this.$store.state.area) {
      this.$store.dispatch("getDetailedArea");
    }
    this.fromPage = this.$route.params.from;
    this.userList();
  },
  methods: {
    userList(){
      addRessList().then((res) => {
        const { data } = res.data;
        this.list = data;
      })
    },
    clickArea(...agr) {
      const selectData = this.$refs.picker.getValues();
      this.codeing = selectData.map((item) => item.area_code);
      const names = selectData.map((item) => item.area_name);
      this.areaShow = false;
      let position = '';
      names.forEach((item) => position += '/' + item)
      this.areaString = position.substr(1,position.length-1);
    },
    cancelArea(){   //下拉取消
      this.areaShow = false;
    },
    showForm() {
      // 显示新增地址表单
      this.formShow = true;
      this.formData.receiver_name = ''
      this.formData.receiver_tel = ''
      this.formData.receiver_identity_card = ''
      this.formData.address = ''
      this.areaString = ''
      // 如果第一次添加地址 设为默认
      if (this.list.length == 0) {
        this.formData.checked = true;
      }
      this.regionalData = this.$store.state.area;
    },
    overseas() {
      //切换国内
      this.columns = [];
      this.$store.state.area.forEach((item) => {
        if (item.area_name == this.texeForeign){
          this.columns.push(item)
        }  //中国
      });
    },
    domestic() {
      //切换国外
      this.columns = [];
      this.$store.state.area.forEach((item) => {
        if (item.area_name == this.textHome){
          this.columns.push(item)
        }//国际
      });
    },
    formCancel() {
      // 取消新增
      this.resetData();
      this.resetFormError();
    },
    idCardIconClick() {
      // 身份证右侧icon点击
      this.$dialog.confirm({
        message: this.language.l_147,  //修改需重新上传身份证
        cancelButtonText: this.language.l_148,  //算了
        confirmButtonText: this.language.l_149,  //去上传
      }).then(() => {
        this.$router.push({ name: "MineIdCard" });
      })
    },
    showArea() {
      // 显示省市区选择
      this.areaShow = !this.areaShow;
      this.textHome = this.$store.state.area[0].area_name;
      this.texeForeign = this.$store.state.area[1].area_name;
      this.domestic();
    },
    updateAddress(data) {
      //用户地址编辑
      // 编辑收货地址
      this.formData.receiver_name = data.receiver_name;
      this.formData.receiver_tel = data.receiver_tel;
      this.formData.receiver_identity_card = data.receiver_identity_card;
      this.formData.countries_code = data.province;
      this.formData.province_code = data.city;
      this.formData.city_code = data.district;
      this.formData.address = data.address;
      this.formData.is_default = data.is_default == 1 ? true : false;
      this.areaString = data.province + data.city + data.district;
      this.updateAdrId = data.id;

      this.isUpdate = true;
      // 显示新增地址表单
      this.formShow = true;
    },
    getArea(e) {
      // 获取选中的省市区
      let codeArr = [];
      let nameArr = [];
      e.forEach((item) => {
        codeArr.push(item.code);
        nameArr.push(item.name);
      });
      this.formData.area = nameArr.join(" ");
      this.areaString = nameArr.join(" ");

      this.areaShow = false;
    },
    inputName(val) {
      // 输入-收货人判断格式
      this.formError.nameError = val ? "" : this.language.l_150;  //收货人姓名不能为空
    },
    inputAdrInfo(val) {
      // 输入-详细地址判断格式
      this.formError.AdrInfoError = val ? "" : this.language.l_151;  //详细地址不能为空
    },
    formVerify(e) {
      // 提交表单验证
      const {
        receiver_name,
        receiver_tel,
        receiver_identity_card,
        userAddress,
        address,
      } = e;
      this.inputName(receiver_name);
      this.inputAdrInfo(address);
      this.formError.AdrError = userAddress ? "" : this.language.l_152;  //请选择地址
    },
    formSubmit(e) {
      // 提交表单
      this.formShow = false;
      const countries_code = this.codeing[0] ? this.codeing[0]:'';
      const province_code = this.codeing[1] ? this.codeing[1]:'';
      const city_code = this.codeing[2] ? this.codeing[2]:'';
      const district_code = this.codeing[3] ? this.codeing[3]:'';
      const is_default = e.is_default? '1':"0";
      const id = this.updateAdrId;
      const {
        receiver_name,
        receiver_tel,
        receiver_identity_card,
        address
      } = e;
      if (this.isUpdate) {
        editAddress(id,{receiver_name,receiver_tel,receiver_identity_card,address,countries_code,province_code,city_code,district_code,is_default}).then(() => {
          this.userList();
          this.$toast.success(this.language.l_153)  //编辑成功
        }).catch(() => this.$toast.fail(this.language.l_154));  //编辑失败
      }
      //新增
      if (!this.isUpdate) {
        addAddress({receiver_name,receiver_tel,receiver_identity_card,address,countries_code,province_code,city_code,district_code,is_default}).then(() => {
          this.userList();
          this.$toast.success(this.language.l_89) //添加成功
        }).catch(() => this.$toast.fail(this.language.l_155));  //添加失败
      }
    },
    selectAddress(data) {
      // 选择收货地址
      const { fromPage } = this;
      if (!fromPage) return;
      if (fromPage == "check") {
        this.$store.commit("setCheckAdr", data);
        this.$router.replace({name: 'OrderCheck'})
      }
    },
    resetData() {
      // 重置表单
      this.formShow = false;
      this.formData.name = "";
      this.formData.phone = "";
      this.formData.idCard = "";
      this.formData.area = "";
      this.formData.adrInfo = "";
      this.formData.checked = false;
      this.areaCodeArr = null;
      this.district_code = null;
      this.isUpdate = false;
      this.updateAdrId = null;
    },
    resetFormError() {
      // 重置错误提醒
      this.formError.nameError = "";
      this.formError.phoneError = "";
      this.formError.idCardError = "";
      this.formError.AdrError = "";
      this.formError.AdrInfoError = "";
    },
    delAddress() {
      // 删除地址
      this.$dialog.confirm({message: this.language.l_156}).then(() => {  //确认删除该地址吗？
        const { updateAdrId: id } = this;
        delAear(id).then(() => {
            this.$toast.success(this.language.l_56);  //删除成功
            this.userList();
            this.formShow = false;
          })
        }).catch(() => this.$toast.fail(this.language.l_313));  //取消删除
    },
  },
  computed: {
    hasIdCard() {
      // 编辑且有身份证
      const { isUpdate } = this;
      const { idCard } = this.formData;
      return isUpdate && idCard != "";
    },
  },
};
</script>

<style lang="less" scoped>
#mineAddress {
  .safe_padding_bottom(50px);
  .getAddress {
    padding-bottom: 15px;
    .van-form {
      .getAdr,
      .delAdr {
        width: calc(100% - 40px);
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin: 10px auto 0;
        border-radius: 4px !important;
        font-size: 16px;
      }
      .getAdr {
        color: #fff;
        background-image: @grad_right;
      }
    }
  }
  .content {
    font-size: 14px;
    .content-wrap {
      background-color: #fff;
      box-sizing: border-box;
      padding: 5px 10px;
      .content-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        .content-item-left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #999999;
          .content-item-avaui {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 50%;
            background-image: @grad_right;
            color: #fff;
            margin-right: 10px;
          }
          .content-userInfo {
            display: flex;
            flex-direction: column;
            color: #333333;
            margin-bottom: 2px;
            width: 270px;
            .content-receiver_name {
              display: flex;
              align-items: flex-end;
              margin-right: 5px;
              font-size: 14px;
              p {
                &:first-child {
                  max-width: 180px;
                }
                &:last-child {
                  font-size: 12px;
                  color: #999999;
                  margin-left: 10px;
                }
              }
            }
            .content-address {
              p {
                line-height: 1.4;
                &.default {
                  float: left;
                  background-color: @c_light;
                  color: #fff;
                  padding: 1px 5px;
                  font-size: 12px;
                  text-align: center;
                  margin-right: 5px;
                }
              }
            }
          }
        }
        .content-change {
          color: @c_deep;
          padding-left: 10px;
          height: 30px;
          line-height: 30px;
          text-align: center;
        }
      }
    }
  }
  .addAdress {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    p {
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      background-image: @grad_right;
    }
    .safe_padding_bottom();
  }
  .switching {
    display: flex;
    justify-content: space-evenly;
    /deep/ .van-button--default{
      background-color: @c_deep;
      color: #fff;
    }
  }
}
</style>